import React, {useContext, useEffect, useState} from "react";
import {Card, Grid, IconButton} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { SetupHttpMonitorRow } from "./SetupHttpMonitorRow";
import { SetupHeader } from "./SetupHeader";
import { SetupFooter } from "./SetupFooter";
import { useAsyncAction } from "../../api/Async";
import { api } from "../../api";
import { BulkAddMonitorsRow } from "../../api/Types";
import { CompanyStatusContext } from "../../core/auth";
import {AddMonitorPopover} from "../header/AddMonitor";
import {newMonitorEventName, newMonitorEvents} from "../AddMonitor";
import {MonitorDetail} from "../monitorList";
import {humanizeSeconds} from "../monitorList/humanize";
import green from "@mui/material/colors/green";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {formatMonitorName, MonitorIcon} from "../monitorList/MonitorHeader";
import {CheckIntervalSlider} from "../header/addMonitor/CheckIntervalSlider";
import {StatusCell} from "./StatusCell";

export const defaultRow = { method: "GET", url: "", checkIntervalMs: 30 * 1000 };
export function autoFixURL(url: string) {
  url = url.trim();
  if (!url) return url;

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "https://" + url;
  }

  return url;
}

export function HttpSetup(props: { onNext(): void }) {
  const status = useContext(CompanyStatusContext);

  const [data, setData] = useState<BulkAddMonitorsRow[]>([
    Object.assign({}, defaultRow),
    Object.assign({}, defaultRow),
    Object.assign({}, defaultRow),
  ]);

  const checkIntervalMs = status && status.status ? status.status.maxPollRateInSeconds * 1000 : null;

  useEffect(() => {
    if(!checkIntervalMs) return;

    const hasIncorrect = data.find(d => d.checkIntervalMs < checkIntervalMs)
    if(!hasIncorrect) return;

    setData(old => old.map(d => {
        if(d.checkIntervalMs < checkIntervalMs) {
            d.checkIntervalMs = checkIntervalMs;
        }

        return Object.assign({}, d);
    }));

  }, [data, checkIntervalMs]);

  const action = useAsyncAction(async () => {
    for (let d of data) {
      d.url = autoFixURL(d.url);
    }

    await api.bulkAddMonitors(data.filter((d) => d.url !== ""));
    status.reload();

    props.onNext();
  }, [data, props]);

  const [addCustom, setAddCustom] = useState<{
    anchor: HTMLElement | null
    mode: string
  }|null>(null)

  const [customItems, setCustomItems] = useState<MonitorDetail[]>([])
  useEffect(() => {
    const listen = (input: MonitorDetail) => {
      setCustomItems(old => [...old, input]);
    }

    newMonitorEvents.addListener(newMonitorEventName, listen)

    return () => {
      newMonitorEvents.removeListener(newMonitorEventName, listen)
    }
  }, []);

  return (
    <React.Fragment>
      <SetupHeader
          title="What would you like to Monitor?"
          stepIndex={0}
      />
      <Card>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((r, index) => (
              <SetupHttpMonitorRow
                key={index}
                value={r}
                onChange={(newValue) => {
                  setData((rows) =>
                    rows.map((oldRow) => {
                      if (oldRow === r) return newValue;
                      return oldRow;
                    })
                  );
                }}
                onRemove={() => {
                  setData((rows) => {
                    const newRows = rows.filter((row) => row !== r);
                    if (newRows.length === 0) {
                      newRows.push(defaultRow);
                    }

                    return newRows;
                  });
                }}
              />
            ))}
            {customItems.map((r, index) => (
                <TableRow>
                    <TableCell>{formatMonitorName(r)}</TableCell>
                    <StatusCell value={r}
                                onRemove={() => {
                                    setCustomItems(old => old.filter((_, i) => i !== index))
                                }}
                                looksValid={true}
                    />
                </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                        onClick={() =>
                            setData((value) => {
                              const newValue = value.slice(0);
                              newValue.push(Object.assign({}, defaultRow));
                              return newValue;
                            })
                        }
                        variant="outlined"
                        color="primary"
                        startIcon={<AddCircleOutlineIcon />}
                    >
                      Add HTTP
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                        onClick={(e) => setAddCustom({
                          anchor: e.currentTarget,
                          mode: "db",
                        })}
                        color="primary"
                        startIcon={<AddCircleOutlineIcon />}
                    >
                      Add DB
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                        onClick={(e) => setAddCustom({
                          anchor: e.currentTarget,
                          mode: "ping",
                        })}
                        color="primary"
                        startIcon={<AddCircleOutlineIcon />}
                    >
                      Add PING
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                        onClick={(e) => setAddCustom({
                          anchor: e.currentTarget,
                          mode: "grpc",
                        })}
                        color="primary"
                        startIcon={<AddCircleOutlineIcon />}
                    >
                      Add GRPC
                    </Button>
                  </Grid>
                </Grid>
                {addCustom && <AddMonitorPopover onClose={() => setAddCustom(null)} anchorEl={addCustom.anchor} initialMode={addCustom.mode} />}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <SetupFooter
        loadingEl={action.LoadingElement}
        onNext={() => action.callback(null)}
      />
    </React.Fragment>
  );
}
