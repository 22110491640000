import { SetupHeader } from "./SetupHeader";
import { Card } from "@mui/material";
import { SetupFooter } from "./SetupFooter";
import React, {useContext, useEffect, useState} from "react";
import {TeamEditor, TeamHook} from "../team/TeamEditor";
import {useTeamMemberCount} from "../Team";
import {useCompanyStatus2} from "../../core/auth";
import {Note} from "../Note";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {FreeTrialModalContext} from "../header/freeTrial/FreeTrialModal";

export function NotificationsSetup(props: { onNext(): void }) {

    const [teamHook] = useState<TeamHook>({
        onSubmit: () => Promise.resolve(),
    });

    const count = useTeamMemberCount();
    const status = useCompanyStatus2();
    const freeTrialCtx = useContext(FreeTrialModalContext);

    if(!status) return null;

  return (
    <React.Fragment>
      <SetupHeader title="Who should get notified?" stepIndex={1} />
        {status.teamMemberCountLimit <= count && <Note
            variant="info"
            text="You can add more team members if you upgrade your plan."
            action={<a style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => freeTrialCtx.show()}>
                <Typography variant="body2" color="textSecondary">
                    How to upgrade
                </Typography>
            </a>}
        />}
      <Card>
        <TeamEditor bigAddButton teamHook={teamHook} />
      </Card>
      <SetupFooter onNext={async () => {
          await teamHook.onSubmit();
          props.onNext();
      }} />
    </React.Fragment>
  );
}
