import { Grid } from "@mui/material";
import React from "react";
import { Logo } from "./Logo";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { Slack } from "./Slack";
import { BodyCenter } from "../Body";
import { NavLink } from "./NavLink";
import { enableTeam } from "../../App";
import { InfoMenu } from "./InfoMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    height100: {
      minHeight: "100%",
    },
    banner: {
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export function Header() {
  const styles = useStyles();

  return (
    <div className={styles.banner}>
      <BodyCenter>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Slack />
              </Grid>
              <Grid item />
              <NavLink route="/monitors">Monitors</NavLink>
              <Grid item />
              <NavLink route="/status-page">Status Pages</NavLink>
              {enableTeam && <Grid item />}
              {enableTeam && (
                <NavLink route="/notifications">Notifications</NavLink>
              )}
              <Grid item>
                <InfoMenu />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BodyCenter>
    </div>
  );
}
