import TableCell from "@mui/material/TableCell";
import {Divider, IconButton, MenuItem, Select} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import { CheckIntervalSlider } from "../header/addMonitor/CheckIntervalSlider";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import TableRow from "@mui/material/TableRow";
import React from "react";
import green from "@mui/material/colors/green";
import { BulkAddMonitorsRow } from "../../api/Types";
import {css} from "@emotion/css";
import {PopoverMenu} from "./PopoverMenu";
import {defaultRow} from "./HttpSetup";
import grey from "@mui/material/colors/grey";
import {StatusCell} from "./StatusCell";

const httpRegex = /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const selectCss = css({
    "& fieldset": {
        border: "none",
    }
})

export function SetupHttpMonitorRow(props: {
  value: BulkAddMonitorsRow;
  onChange(newValue: BulkAddMonitorsRow): void;
  onRemove(): void;
}) {
  const looksValid = props.value.url.match(httpRegex) !== null;

  return (
    <TableRow>
      <TableCell padding="checkbox" style={{ width: "auto" }}>
        <TextField
          style={{ minWidth: 100 }}
          fullWidth
          variant="standard"
          value={props.value.url}
          InputProps={{ disableUnderline: true }}
          placeholder="api.mydomain.com/health"
          onChange={(e) =>
            props.onChange(
              Object.assign({}, props.value, {
                url: e.target.value,
              })
            )
          }
        />

      </TableCell>
      <StatusCell value={props.value}
                  onChange={props.onChange}
                  httpMode
                  looksValid={looksValid}
      />
    </TableRow>
  );
}
