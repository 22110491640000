import React from "react";
import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeProvider } from "@mui/styles";
import { InternalDashboard } from "./InternalDashboard";
import { StatusPage } from "./StatusPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { MonitorList, Spacer } from "./components/monitorList";
import { Team } from "./components/Team";
import { ResetPassword } from "./components/ResetPassword";
import { NotificationSettings } from "./components/NotificationSettings";
import { defaultMuiTheme } from "./Theme";
import { AuthProvider } from "nate-react-api-helpers";
import { SetupPage } from "./components/setup/SetupPage";
import { PageTitle } from "./components/PageTitle";
import {RegisterPage} from "./components/login/RegisterPage";
import {Login} from "./components/Login";
import {ManageStatusPages} from "./components/manageStatusPage/ManageStatusPages";
import { RegisterLifetimePage } from "./components/registerLifetime/RegisterLifetimePage";
import {createTheme, Theme} from "@mui/material/styles";
import {StyledEngineProvider} from "@mui/material";
import { AutoRedirectRoot } from "./components/AutoRedirectRoot";
import {ResetPassword2} from "./components/ResetPassword2";
import {ShowPlans} from "./components/ShowPlans";
import {api} from "./api";
import {MonitorOrSetup} from "./components/MonitorOrSetup";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme(defaultMuiTheme);

export const enableTeam = true;
export const enableSetup = true;

class App extends React.PureComponent<{}> {
  render() {
    return (
      <AuthProvider onPing={() => api.ping()}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router>
                  <Switch>
                    <Route path="/status/:id">
                      <StatusPage />
                    </Route>
                    <Route exact path="/status-page">
                      <InternalDashboard>
                        <ManageStatusPages />
                      </InternalDashboard>
                    </Route>
                    <Route exact path="/pricing">
                      <InternalDashboard>
                        <ShowPlans />
                      </InternalDashboard>
                    </Route>
                    <Route exact path="/setup">
                      <InternalDashboard hideFreeTrial>
                        <MonitorOrSetup sticky />
                      </InternalDashboard>
                    </Route>
                    <Route path="/confirm-email/:code">
                      <ResetPassword />
                    </Route>
                    <Route path="/register" exact>
                      <RegisterPage />
                    </Route>
                    <Route path="/register/lifetime">
                      <RegisterLifetimePage />
                    </Route>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/reset-password/:token">
                      <ResetPassword2 />
                    </Route>
                    <Route exact path="/notifications">
                      <InternalDashboard>
                        <PageTitle
                          title="Notification Settings"
                          subtitle="Tell the right people when things go wrong"
                        />
                        <NotificationSettings />
                        <Spacer />
                        <Team />
                      </InternalDashboard>
                    </Route>
                    <Route exact path="/">
                      <InternalDashboard hideFreeTrial>
                        <AutoRedirectRoot />
                      </InternalDashboard>
                    </Route>
                    <Route exact path="/monitors">
                      <InternalDashboard hideFreeTrial>
                        <MonitorOrSetup />
                      </InternalDashboard>
                    </Route>
                    <Route path="*">
                      <Redirect to="/" />
                    </Route>
                  </Switch>
                </Router>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AuthProvider>
    );
  }
}

export default App;
