import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {Menu} from "@mui/material";

export function PopoverMenu(props: {
    children(onClose: () => void): any;
}) {
    const [anchor, setAnchor] = useState(null);

    return (
        <>
            <IconButton onClick={e => setAnchor(e.currentTarget as any)}>
                <MoreVertIcon />
            </IconButton>
            {anchor && <Menu open anchorEl={anchor} style={{minWidth: 250}} onClose={() => setAnchor(null)}>
                {props.children(() => setAnchor(null))}
            </Menu>}
        </>
    )
}