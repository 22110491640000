import grey from "@mui/material/colors/grey";
import {Divider, IconButton, MenuItem} from "@mui/material";
import green from "@mui/material/colors/green";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {PopoverMenu} from "./PopoverMenu";
import {defaultRow} from "./HttpSetup";
import TableCell from "@mui/material/TableCell";
import React from "react";
import {BulkAddMonitorsRow} from "../../api/Types";
import { red } from "@mui/material/colors";

export function StatusCell(props: {
    value: BulkAddMonitorsRow;
    looksValid: boolean;
    httpMode?: boolean;
    onChange?(newValue: BulkAddMonitorsRow): void;
    onRemove?(): void;
}) {
    const http = props.httpMode;

    return (
        <TableCell style={{ paddingTop: 0, paddingBottom: 0, width: 140 }}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                {http && props.value.method !== "GET" && <div style={{fontSize: "1rem", color: grey["600"]}}>
                    {props.value.method}
                </div>}
                {props.looksValid && <IconButton style={props.looksValid ? { color: green["600"] } : {}} size="small">
                    <CheckCircleIcon color="inherit" />
                </IconButton>}

                <PopoverMenu>
                    {onClose => (
                        <>
                            {http && <>
                                {props.value.method !== "GET" && <MenuItem onClick={() => {
                                    if(!props.onChange) return;
                                    props.onChange(
                                        Object.assign({}, props.value, {
                                            method: "GET",
                                        })
                                    )
                                    onClose();
                                }}>Use http verb GET</MenuItem>}
                                <MenuItem onClick={() => {
                                    if(!props.onChange) return;
                                    props.onChange(
                                        Object.assign({}, props.value, {
                                            method: "PUT",
                                        })
                                    )
                                    onClose();
                                }}>Use http verb PUT</MenuItem>
                                <MenuItem onClick={() => {
                                    if(!props.onChange) return;
                                    props.onChange(
                                        Object.assign({}, props.value, {
                                            method: "POST",
                                        })
                                    )
                                    onClose();
                                }}>Use http verb POST</MenuItem>
                                <Divider />
                            </>}
                            {props.onChange && <MenuItem onClick={() => {
                                if(!props.onChange) return;
                                props.onChange(Object.assign({}, defaultRow));
                                onClose();
                            }}>Clear</MenuItem>}
                            {props.onRemove && <MenuItem style={{color: red["800"]}} onClick={() => {
                                if(!props.onRemove) return;
                                props.onRemove();
                                onClose();
                            }}>Remove</MenuItem>}
                        </>
                    )}
                </PopoverMenu>
            </div>
        </TableCell>
    )
}