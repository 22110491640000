import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const steps = ["Monitors", "Notifications"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      backgroundColor: "transparent",
      padding: 0,
    },
  })
);

export function SetupHeader(props: { title: string; stepIndex: number }) {
  const styles = useStyles();

  return (
    <React.Fragment>
      <div style={{ height: 30 }} />
      <div style={{ paddingLeft: 10 }}>
        <Grid container>
          <Grid item xs>
            <Typography variant="h4">{props.title}</Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: 20 }} />
    </React.Fragment>
  );
}
