import React, {useContext, useEffect, useState} from "react";
import { HttpSetup } from "./HttpSetup";
import { NotificationsSetup } from "./NotificationsSetup";
import { DonePageSetup } from "./DonePageSetup";
import {CrushAnimationContext} from "../CrushAnimation";
import { useHistory } from "react-router-dom";
import {useCompanyStatus2} from "../../core/auth";

enum State {
  Monitors,
  Notifications,
  Status,
  Done,
}

export function SetupPage() {
  const [state, setState] = useState(State.Monitors);
  const { trigger } = useContext(CrushAnimationContext);
  const history = useHistory();

  switch (state) {
    case State.Monitors:
      return <HttpSetup onNext={() => {
        setState(State.Notifications)
      }} />;
    case State.Notifications:
      return <NotificationsSetup onNext={() => {
        trigger();
        history.push("/monitors");
      }} />;
    // case State.Status: return <StatusPageSetup onNext={() => setState(State.Done)} />;
    default:
    case State.Done:
      return <DonePageSetup />;
  }
}
