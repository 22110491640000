import {MonitorList} from "./monitorList";
import React, {useContext, useEffect} from "react";
import {SetupPage} from "./setup/SetupPage";
import CircularProgress from "@mui/material/CircularProgress";
import {CompanyStatusContext} from "../core/auth";

export function MonitorOrSetup(props: {
    sticky?: boolean
}) {

    const ctx = useContext(CompanyStatusContext);
    const [stickyHasMon, setStickyHasMon] = React.useState<null | boolean>(null);
    const loading = ctx.isLoading || !ctx.status;
    const hasMon = ctx.status?.onboarding.hasMonitors || false;

    useEffect(() => {
        if(loading) return;
        if(stickyHasMon !== null) return;

        setStickyHasMon(hasMon);
    }, [loading, hasMon, stickyHasMon]);

    if(loading) {
        return (
            <CircularProgress />
        )
    }

    if(props.sticky ? stickyHasMon : hasMon) {
        return <MonitorList />
    }

    return <SetupPage />;
}